.App-header {
  font-size: calc(10px + 2vmin);
  color: white;

}
.language-picker{
  position: absolute;
  right: 20px;
}  

#add-wall:hover {
  background-color: rgb(214,125,58)
}

#calculate-button {
  background-color: rgb(41,47,85);
}

.wall-title {
  color: rgb(41,47,85);
}

table thead {
  color: rgb(41,47,85) ;
}
.color-primary{
  color: rgb(41,47,85) ;
}
.bg-primary{
  background-color: rgb(41,47,85);
}

/* Tour CSS*/

.dkkZOv{
  background: rgba(249,115,22,1) !important;
  top:20px !important;
  left:15px !important;
}
.reactour__helper:focus, .reactour__helper{
  outline:0 !important;
  border:0;

}
.reactour__helper{
  padding:20px 40px 20px 60px !important;
  border-top:3px solid rgba(249,115,22,1) !important;
}
.diKbXs{
  margin-top:15px !important;
  justify-content: flex-start !important;
}
.reactour__dot{
  border:1px solid #f0f0f0 !important;;
  background:#f0f0f0 !important;
}
.reactour__dot:hover{
  color:#ccc !important;
  background:#ccc !important;
}
.reactour__dot--is-active,.reactour__dot--is-active:hover{
  color: rgba(249,115,22,1) !important;
  background: rgba(249,115,22,1) !important;
  border-color:rgba(249,115,22,1) !important;
}
.fbKdtY{
  margin-right:10px !important;
}

.cAxQge {
  margin-left:10px !important;
}


/* Inputs */
.custom-input-dimm{
  border-color:#ccc !important;
  outline:0 !important;
  box-shadow:0 !important;
}
.custom-input-dimm:focus{
  border-color:rgba(249,115,22,1) !important;
  box-shadow: none !important;
} 
.peer{
  box-shadow: none !important;
}
.peer:after{
  box-shadow: none !important;
  border:1px solid #fff !important;
}
.custom-checkbox,.custom-checkbox:focus{
  outline:0 !important;
  box-shadow:none !important;
}
#units{
  border:1px solid #f0f0f0 !important;
}
.custom-input-dimm-text{
  font-size:0.85rem;
  font-weight: 500;
  background:#f0f0f0;
  border-radius: 4px;
  border:1px solid #f0f0f0;
  color:rgba(41,47,85,0.7);
}
[type='checkbox']:checked{
  background-color:rgba(249,115,22,1) !important;
}
[type='checkbox']{
  border-color:#ccc !important;
  border-radius: 2px;
}
.grid-align-center{
  align-items: center;
}
.opacity-50{
  opacity:0.5;
}
#ruler{
  opacity: 0.5;
}
button{
  border-radius: 4px !important;
}
button:focus{
  box-shadow: none !important;
}
.image-button{
  cursor: pointer;
}
.image-button:not(.selected) img{
  opacity: .6;
}
.image-button label{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  background-color: #374474;
  color: white;
  padding: 5px;
  border-radius: 0 0 5px 5px;
}
.image-button:not(.selected) label{
  background-color: #e3e3e3;
  color: #6B7280;
}
.back-button{
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}